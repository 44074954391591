import { getIsWidget } from 'js/helpers/page-data';
import {
  MPInitOptions,
  MPUserIdentities,
  mParticleInit,
  mParticleLogEvent,
  mParticleEventType,
} from '@treatwell/ui';
import {
  getDeviceId,
  growthbookInitialiseClient,
} from '@treatwell/growthbook-js';
import { twEnv, isTest, isProductionEnvironment } from './helpers/environment';
import { development, production } from './growthbook-config';
import { configureSentry } from './sentry-config';

const channel = window.__state__.channel;
const accountDetails = window.__state__.accountDetails;
const isWidget = getIsWidget(window.__state__);

function initMParticle(deviceId: string) {
  if (isWidget || isTest()) {
    return;
  }
  Promise.all([
    import(/* webpackChunkName: "mparticle_web-sdk" */ '@mparticle/web-sdk'),
    import(
      /* webpackChunkName: "mparticle_web-onetrust-kit" */ '@mparticle/web-onetrust-kit'
    ),
  ]).then(([mParticle, mParticleOneTrust]) => {
    const options: MPInitOptions = {
      isDevelopmentMode: twEnv !== 'prod',
      deviceId,
    };
    const { accountId, email } = accountDetails;
    const userIdentities: MPUserIdentities = {};

    mParticleOneTrust.register(options);

    if (accountId) {
      userIdentities.customerid = `${accountId}`;

      if (email) {
        userIdentities.email = email;
      }
    }

    mParticleInit(mParticle.default, options, userIdentities);
  });
}

const growthbookConfig = isProductionEnvironment ? production : development;
growthbookConfig.apiHost = `${location.origin}/api/v2/experiments`;

export async function initialiseCoreLibraries() {
  const deviceId = await getDeviceId();
  const isAuthenticated =
    accountDetails.loginStatus === 'account_authenticated';
  growthbookConfig.trackingCallback = (experiment, result) => {
    mParticleLogEvent('$experiment_started', mParticleEventType.Other, {
      platform: 'web',
      is_authenticated: isAuthenticated,
      'Experiment name': experiment.key,
      'Variant name': result.key,
      country: channel.country.countryCode,
    });
  };
  const growthbook = await growthbookInitialiseClient(
    growthbookConfig,
    deviceId,
    {
      growthbookAttributes: {
        channel: {
          country: channel.country.countryCode,
        },
        user: {
          account_id: String(accountDetails.accountId),
          is_authenticated: isAuthenticated,
        },
      },
    }
  );

  // we do not need to wait for these libraries to be initialised
  initMParticle(deviceId);
  configureSentry();

  // return growthbook as it is necessary for GrowthBookProvider
  return growthbook;
}
